import React, { useEffect, useState } from 'react';
import { Select, Button, Spin, Row, Col, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AddSiteModal from './AddSiteModal';
import AddContactModal from './AddContactModal';
import { getCustomerContactsRequest, getCustomerSitesRequest } from '../../../core/account/accountActions';
import './style.scss';

const DeliveryDetails = () => {
    const dispatch = useDispatch();
    const {
        customerSites,
        isFetchingCustomerSites,
        customerContacts,
        isFetchingCustomerContacts,
    } = useSelector(state => state.account);
    const [showAddSiteModal, setShowAddSiteModal] = useState(false);
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);

    const onChangeSite = (val) => {
        setSelectedSite(customerSites.find(site => site.ADR_ID === val));
        document.getElementById('address_id').value = val;
    };

    const onChangeContact = (val) => {
        document.getElementById('site_user_id').value = val;
    };

    useEffect(() => {
        dispatch(getCustomerSitesRequest());
        dispatch(getCustomerContactsRequest());
    }, []);

    return (
        <div className="delivery-details-container">
            {!isFetchingCustomerSites ? (
                <>
                    <Row>
                        <Col span={9}>Site</Col>
                        <Col span={13}>
                            <Form.Item required>
                                <Select defaultValue="Select a site.." style={{ width: '100%' }} onChange={(val) => onChangeSite(val)}>
                                    {customerSites.map(site => (
                                        <Select.Option value={site.ADR_ID}>{site.ADR_NAME}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button onClick={() => setShowAddSiteModal(true)}>+</Button>
                            <AddSiteModal setShowModal={setShowAddSiteModal} showModal={showAddSiteModal} />
                        </Col>
                    </Row>
                    {selectedSite && (
                        <Row>
                            <Col span={24} className="selected-site-table-container">
                                <table className="selected-site-table">
                                    <tr>
                                        <th>ADDRESS LINE 1</th>
                                        <td>{selectedSite.ADR_LINE1}</td>
                                    </tr>
                                    <tr>
                                        <th>ADDRESS LINE 2</th>
                                        <td>{selectedSite.ADR_LINE2}</td>
                                    </tr>
                                    <tr>
                                        <th>TOWN/CITY</th>
                                        <td>{selectedSite.ADR_TOWN}</td>
                                    </tr>
                                    <tr>
                                        <th>COUNTY</th>
                                        <td>{selectedSite.ADR_COUNTY}</td>
                                    </tr>
                                    <tr>
                                        <th>POSTCODE</th>
                                        <td>{selectedSite.ADR_POSTCODE}</td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                    )}
                </>
            ) : <Spin />}
            {!isFetchingCustomerContacts ? (
                <Row>
                    <Col span={9}>Contact</Col>
                    <Col span={13}>
                        <Form.Item required>
                            <Select defaultValue="Select a contact.." style={{ width: '100%' }} onChange={(val) => onChangeContact(val)}>
                                {customerContacts.map(contact => (
                                    <Select.Option value={contact.id}>{contact.firstName} {contact.lastName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Button onClick={() => setShowAddContactModal(true)}>+</Button>
                        <AddContactModal setShowModal={setShowAddContactModal} showModal={(showAddContactModal)} />
                    </Col>
                </Row>
            ) : <Spin />}
        </div>
    );
};

export default DeliveryDetails;
